const ENV = process.env.REACT_APP_ENV;
let URL_PREFIX = "http://localhost:3001";
let SERVER_URL = "http://localhost:3000";

const ENCRYPT_KEY = "qDRCxQ6VZ9q0qvbUiMejRxCmexUd96WQ";

console.log(ENV);

URL_PREFIX = "https://qr.samraam.com";
SERVER_URL = "https://api-gateway2.samraam.com";

// switch (ENV) {
//   case "prod":
// URL_PREFIX = "https://qr.samraam.com";
// SERVER_URL = "https://api-gateway2.samraam.com";
//     break;
//   case "staging":
//     URL_PREFIX = "https://qr-staging.samraam.com";
//     SERVER_URL = "https://api-gateway-staging.samraam.com";
//     break;
//   case "dev":
//     URL_PREFIX = "http://localhost:3001";
//     SERVER_URL = "http://localhost:3000";
//     break;
// }

export { ENCRYPT_KEY, ENV, SERVER_URL, URL_PREFIX };

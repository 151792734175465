import { Photograph } from "@components/Photograph";
import { Status } from "@components/Status";
import { Thumbnail } from "@components/Thumbnail";
import { PropertyDetailProps } from "@hooks/useProperty";
import { currencyFormatter } from "@utils/currency";

interface Props {
  detail: PropertyDetailProps;
}

export const PropertyDetail = ({ detail }: Props) => {
  return (
    <div className="mx-4 rounded-xl bg-white p-4 pt-1">
      <h3 className="text-xl font-extrabold">Detail</h3>
      {detail.Photo ? (
        <div className="flex w-full flex-col items-center justify-center">
          <Thumbnail photo={detail.Photo} />
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-center">
          <Photograph />
          <span>No photo</span>
        </div>
      )}
      <div className="divide-y-[1px]">
        <>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="w-2/6 flex-none font-semibold">Property ID</div>
              <div className="w-auto">{detail.OBJECTID}</div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="w-2/6 flex-none font-semibold">Property Type</div>
              <div className="w-auto">{detail.Type}</div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="w-2/6 flex-none font-semibold">Property Name</div>
              <div className="w-auto">{detail.Name ? detail.Name : "N/A"}</div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="w-2/6 flex-none font-semibold">Owner Name</div>
              <div className="w-auto">
                {detail.OwnerName ? detail.OwnerName : "N/A"}
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex=none w-2/6 font-semibold">
                Collection Fee
              </div>
              <div className="w-auto">
                {detail.CollectionFee &&
                  `${currencyFormatter(detail.CollectionFee)} ៛`}
                {!detail.CollectionFee && "N/A"}
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="w-2/6 flex-none font-semibold">Status</div>
              <div className="w-auto">
                <Status
                  isActive={detail.IsActive}
                  isSuspended={detail.IsSuspense}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export const KH_MONTHS = [
  "មករា",
  "កុម្ភៈ",
  "មិនា",
  "មេសា",
  "ឧសភា",
  "មិថុនា",
  "កក្កដា",
  "សីហា",
  "កក្កដា",
  "តុលា",
  "វិច្ឆិកា",
  "ធ្នូ",
];

import { Photograph } from "@components/Photograph";
import { Status } from "@components/Status";
import { Thumbnail } from "@components/Thumbnail";
import { currencyFormatter } from "@utils/currency";

interface Props {
  detail: any;
}

const optionFields = {
  Bars: "បារ",
  Floors: "ជាន់",
  Restaurants: "អាហារដ្ឋាន",
  Room: "បន្ទប់",
  Floor_Area: "ផ្ទៃជាន់",
  Gym: "កន្លែងហាត់ប្រាណ",
  Bed: "គ្រែ",
  Land_Size: "ទំហំដី",
  BuildingSize: "ទំហំអាគារ",
  Capacity: "ទំហំផ្ទុក",
  TableCount: "ចំនួនតុ",
  BuildingCount: "ចំនួនអគារ",
  Cafes: "ហាងកាហ្វេ",
  Building: "អគារ",
  Chairs: "កៅអុី",
  Businesses: "អាជីវកម្ម",
  Marts: "ម៉ាត",
};

const suffixOptionFields = {
  Bars: "",
  Floors: "",
  Restaurants: "",
  Room: "បន្ទប់",
  Floor_Area: "ម",
  Gym: "",
  Bed: "",
  Land_Size: "ម",
  BuildingSize: "ម",
  Capacity: "",
  TableCount: "",
  BuildingCount: "",
  Cafes: "",
  Building: "",
  Chairs: "",
  Businesses: "",
  Marts: "",
};

const renderOptionalField = (label: any, value: string, suffix: string) => {
  return (
    <div className="py-2">
      <div className="flex">
        <div className="w-2/6 font-semibold">{label}</div>
        <div className="ml-10">
          {suffix === "ម" ? (
            <span>{value} ម&sup2;</span>
          ) : (
            <span>
              {value} {suffix}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export const PropertyDetailFull = ({ detail }: Props) => {
  const shouldRenderProperty = (fieldName: string) => {
    // @ts-ignore
    return (
      detail &&
      detail[fieldName] !== undefined &&
      detail[fieldName] !== null &&
      detail[fieldName] !== 0
    );
  };

  // prettier-ignore
  return (
    <div className="p-4 mx-4 bg-white rounded-xl">
      <h3 className="text-xl font-extrabold">Detail</h3>
      {detail.Photo ? (
        <div className="flex flex-col items-center justify-center w-full">
          <Thumbnail photo={detail.Photo} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full">
          <Photograph />
          <span>No photo</span>
        </div>
      )}
      <div className="divide-y-[1px]">
        <>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">Property ID</div>
              <div className="w-auto">{detail.OBJECTID}</div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">Property Type</div>
              <div className="w-auto">{detail.Type}</div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">Property Name</div>
              <div className="w-auto">{detail.Name ? detail.Name : "N/A"}</div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">Owner Name</div>
              <div className="w-auto">
                {detail.OwnerName ? detail.OwnerName : "N/A"}
              </div>
            </div>
          </div>

          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">Owner Phone</div>
              <div className="w-auto">
                {detail.OwnerPhone ? detail.OwnerPhone : "N/A"}
              </div>
            </div>

          </div>
          
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">
                Distribute month
              </div>
              <div className="w-auto">
                {detail.DistributeMonth ? detail.DistributeMonth : "N/A"}
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex gap-x-8">
              <div className="flex-none w-2/6 font-semibold">Status</div>
              <div className="w-auto">
                <Status
                  isActive={detail.IsActive}
                  isSuspended={detail.IsSuspense}
                />
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex">
              <div className="w-2/6 font-semibold">Province</div>
              <div className="ml-10">
                {detail.Province ? detail.Province : "N/A"}
              </div>
            </div>
          </div>

          <div className="py-2">
            <div className="flex">
              <div className="w-2/6 font-semibold">Collector</div>
              <div className="ml-10">
                {detail.Collector ? detail.Collector : "N/A"}
              </div>
            </div>
          </div>

          {Object.keys(optionFields).map((key, index) => {
            if (shouldRenderProperty(key)) {
              // @ts-ignore
              return renderOptionalField(optionFields[key], detail[key], suffixOptionFields[key]);
            }
          })}

          <div className="py-2">
            <div className="flex">
              <div className="w-2/6 font-semibold">Collection Fee</div>
              <div className="ml-10">
                {detail.CollectionFee && `${currencyFormatter(detail.CollectionFee)} ៛`}
                {!detail.CollectionFee && "N/A"}
              </div>
            </div>
          </div>

          <div className="py-2">
            <div className="flex">
              <div className="w-2/6 font-semibold">Old fee</div>
              <div className="ml-10">
                {detail.OldFee && `${currencyFormatter(detail.OldFee)} ៛`}
                {!detail.OldFee && "N/A"}
              </div>
            </div>
          </div>

          <div className="py-2">
            <div className="flex">
              <div className="w-2/6 font-semibold">Note</div>
              <div className="ml-10">{detail.Note}</div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

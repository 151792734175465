interface Props {
  isActive: null | number;
  isSuspended: null | 0 | 1;
  isClosed?: null | 0 | 1;
}

const renderStatus = (text: string, color: string) => {
  return (
    <div className={`rounded-lg p-1 px-3`} style={{ background: color }}>
      {text}
    </div>
  );
};

export const Status = ({ isActive, isSuspended }: Props) => {
  switch (isActive) {
    case 1:
      return renderStatus("សកម្ម", "#50e3c2");
    case 4:
      return renderStatus("អតិថិជន Cintri", "#1990ff");
    case 5:
      return renderStatus("មិនទាន់បង់លុយ", "green");
    case 6:
      return renderStatus("កំណត់ហេតុ", "#ffcc00");
    case 7:
      return renderStatus("មិនសហការ", "#000000");
    case 8:
      return renderStatus("អតិថិជនបិទ", "gray");
    default:
      return renderStatus("អសកម្ម", "#ffabab");
  }
};

import { ENCRYPT_KEY } from "@/environment";

var CryptoJS = require("crypto-js");

export const encrypt = (value: any) => {
  return CryptoJS.AES.encrypt(value, ENCRYPT_KEY).toString();
};

export const decrypt = (hashValue: string) => {
  const bytes = CryptoJS.AES.decrypt(hashValue, ENCRYPT_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

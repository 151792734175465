import moment from "moment";

import { KH_MONTHS } from "@constants/months";

export const dateFormatter = (date: number) => {
  return moment(date).format("DD/MM/YYYY");
};

export const getKhmerMonth = (date: number) => {
  return KH_MONTHS[moment(date).month()];
};

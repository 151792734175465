import { SERVER_URL } from "@/environment";
import { Spinner } from "@components/Spinner";
import { PropertyBillings } from "@pages/PropertiesPage/components";
import { PropertyDetailFull } from "@pages/PropertiesPage/components/PropertyDetailFull";
import axios from "axios";
import { useState } from "react";

export const Home = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const handleValueChange = (e: any) => {
    setValue(e.target.value);
    console.log(e.target.value);
  };

  const handleSearch = async () => {
    setLoading(true);
    setData(null);

    const url = `${SERVER_URL}/api/properties/${value}&outFields=*`;
    axios
      .get(url)
      .then((res: any) => {
        console.log("data", res.data);

        setData(res.data);
        // setShowModal(true);
      })
      .catch((e) => {
        alert("Error occur");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // prettier-ignore
  return (
    <div className="app-container">
      {/* <div className="container flex bg-lime-400 py-5 mb-10">
        <div className="w-9/12 m-auto">Logo</div>
      </div> */}
      
      <div>
        <div className="xl:w-c:\Program Files\Microsoft VS Code\resources\app\out\vs\code\electron-sandbox\workbench\workbench.html96 flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                aria-hidden="true"
                className="h-5 w-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              value={value}
              onChange={handleValueChange}
              type="text"
              id="simple-search"
              className="light:bg-gray-700 light:text-white block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900  focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="លេខសំគាល់អតិថិជន"
              required
            />
          </div>
          <button className="ml-2 rounded-lg border border-blue-700 bg-blue-700 p-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {loading ? (
              <Spinner
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 0,
                }}
              />
            ) : (
              <>
                <div style={{ display: "flex" }} onClick={handleSearch}>
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                  <span>ស្វែងរក</span>
                </div>
                <span className="sr-only">ស្វែងរក</span>
              </>
            )}
          </button>
        </div>
      </div>

      {data && (
        <div className="detail-container">
          <PropertyDetailFull detail={data?.property} />
          <PropertyBillings
            billings={data?.billings}
            property={data?.property}
          />
        </div>
      )}
    </div>
  );
};

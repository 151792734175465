import { PropertyBillingProps } from "@hooks/useProperty";
import { BillingCard } from "./BillingCard";
import moment from "moment";

interface Props {
  billings: PropertyBillingProps[];
  property: any;
}

export const PropertyBillings = ({ billings, property }: Props) => {
  // prettier-ignore
  return (
    <div className="p-4 pt-1 m-4 bg-white rounded-xl">
      <h3 className="text-xl font-extrabold">Billings</h3>
      {billings && billings.length > 0 ? (
        <>
          {billings.map((billing: PropertyBillingProps) => {
            const monthStr = moment(billing?.BillForDate).add(1, 'd').format('MM/YYYY');
            
            const isDistributed = property?.DistributeMonth?.includes(monthStr);
             
            return (
              <BillingCard
                key={billing.OBJECTID}
                billing={billing}
                distributed={isDistributed}
              />
            );
          })}
        </>
      ) : (
        <div>Empty</div>
      )}
    </div>
  );
};

import axios from "axios";
import useSWR, { Fetcher } from "swr";

import { SERVER_URL } from "@/environment";
import { decrypt } from "@utils/encryption";

export interface PropertyDetailProps {
  OBJECTID: number;
  Type: string;
  Name: null | string;
  OwnerName: null | string;
  CollectionFee: null | number;
  LastBilledDate: null | number;
  IsActive: null | 0 | 1 | 2;
  IsSuspense: null | 0 | 1;
  Isclosed: null | 0 | 1;
  Photo: null | string;
}

export interface PropertyBillingProps {
  OBJECTID: number;
  CreateDate: number;
  Amount: number;
  Paid: null | 0 | 1;
  BillForDate: number;
  PropertyID: number;
  EditDate: number;
}

interface PropertyProps {
  property: PropertyDetailProps;
  billings: PropertyBillingProps[];
}

const fetcher: Fetcher<PropertyProps, string> = (url: string) => {
  return axios.get(url).then((res: any) => res.data);
};

export default function useProperty(id: string | string[] | undefined) {
  let decryptedId = "";
  if (typeof id === "string") decryptedId = decrypt(id);

  const url = `${SERVER_URL}/api/properties/${decryptedId}`;

  const { data, error } = useSWR<PropertyProps>(
    id ? url : null,
    id ? fetcher : null
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

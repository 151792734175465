import { PropertyBillingProps } from "@hooks/useProperty";
import { currencyFormatter } from "@utils/currency";
import { getKhmerMonth } from "@utils/date";

interface Props {
  billing: PropertyBillingProps;
  distributed?: boolean;
}
export const BillingCard = ({ billing, distributed }: Props) => {
  // prettier-ignore
  return (
    <div className="my-4 items-start justify-between rounded-md border-2 p-4 sm:flex">
      <div className="flex-col">
        <div className="pb-1">
          <span className="font-bold">ID: {billing.OBJECTID}</span> (ខែ
          {getKhmerMonth(billing.BillForDate)})
        </div>
        <div className="pb-1 font-bold">{`${currencyFormatter(
          billing.Amount
        )} ៛`}</div>
        {distributed ? (
          <div className="text-green-500">បានចែករួចរាល់</div>
        ) : (
          <div className="text-rose-500">មិនទាន់បានចែក</div>
        )}
      </div>
      <div className="flex-col justify-between pt-2">
        <div>
          {billing.Paid === 0 ? (
            <span className="rounded-lg bg-inactive px-4 py-1">
              មិនទាន់ទូទាត់
            </span>
          ) : (
            <span className="rounded-lg bg-active px-4 py-1">ទូទាត់រួច</span>
          )}
        </div>
      </div>
    </div>
  );
};
